@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;700&family=Russo+One&display=swap');

body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  font-family: 'Rajdhani', sans-serif;
}

.App {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
}

canvas {
  display: block; /* Ensures the canvas is a block element */
  width: 100vw;   /* Full viewport width */
  height: 100vh;  /* Full viewport height */
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}

.container img {
  width: 18vmin; /* Adjusted for both width and height */
  height: auto;
  max-width: 208px; /* Ensures the logo is double the previous size */
  margin-bottom: 3vmin;
  border-radius: 100%;
  box-shadow: rgba(22, 145, 204, 0.75) 0 0 20vmin;
}

h1 {
  font-size: 5vmin; /* Adjusted for both width and height */
  margin: 0;
  font-family: 'Russo One', sans-serif;
  width: max-content;
  text-align: center;
}

h2 {
  font-size: 2.8vmin; /* Adjusted for both width and height */
  margin: 0;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 300;
  text-align: center;
}

@media (max-width: 768px) {
  .container img {
    width: 36vmin; /* Increase size for mobile devices */
  }

  h1 {
    font-size: 6vmin; /* Increase size for mobile devices */
  }

  h2 {
    font-size: 3.5vmin; /* Increase size for mobile devices */
  }
}
